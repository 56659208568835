// @utilities
const { APIPost } = require('utilities/APIRequestHelper');
const apiURLBuilder = require('core/apiURLBuilder');
const errorActions = require('actions/brokerError');

// @ constants
const actionTypes = require('constants/actionTypes');
const { BLOCKED_ACCOUNTS } = require('constants/flagBasedFeatures');

const resetBlockedAccounts = () => ({
    type: actionTypes.BLOCKED_ACCOUNTS_RESET,
});

const blockedAccountsRequest = () => ({
    type: actionTypes.BLOCKED_ACCOUNTS_REQUEST,
});

const blockedAccountsSuccess = data => ({
    type: actionTypes.BLOCKED_ACCOUNTS_SUCCESS,
    payload: {
        data
    }
});

const blockedAccountsFailure = error => ({
    type: actionTypes.BLOCKED_ACCOUNTS_FAILURE,
    payload: {
        error
    }
});

const getBlockedAccounts = (accounts, operationId, isSharedAccounts = false) => (dispatch, getState) => {
    const isInactiveAccountsEnabled = getState().flagBasedFeatures.get(BLOCKED_ACCOUNTS);

    if (!isInactiveAccountsEnabled) {
        return;
    }

    const contractNumber = isSharedAccounts ? 'contractNumber' : 'newContractNumber';
    const product = isSharedAccounts ? 'product' : 'newProduct';

    const cuentas = accounts.map(account => ({
        numeroDeContrato: account.get(contractNumber),
        producto: account.get(product)
    })).toArray();

    const requestData = {
        query: {
            cuentas
        }
    }

    dispatch(blockedAccountsRequest());

    return APIPost(dispatch, apiURLBuilder.getURL('blockedAccounts', { operationId }), requestData)
        .then(response => {
            dispatch(blockedAccountsSuccess(response));
        })
        .catch(error => {
            dispatch(blockedAccountsFailure(error));
        });
}

const fetchingBlockedAccountBySepblac = state => ({
    type: actionTypes.FETCHING_BLOCKED_ACCOUNT_BY_SEPBLAC,
    payload: state,
});

const getBlockedAccountBySepblac = history => (dispatch, getState) => {
    const accountsById = getState().accounts.get('byId');
    const contracto = accountsById.map(account => {
        return {
            numerodecontrato: account.get('newContractNumber'),
            producto: account.get('newProduct'),
        };
    });
    const requestData = {
        query: {
            accounts: Object.values(contracto.toJS()),
        },
    };
    dispatch(fetchingBlockedAccountBySepblac(true));
    return APIPost({
        dispatch,
        endpoint: 'validateBlockedAccount',
        params: requestData,
    })
        .then(() => {
            dispatch(fetchingBlockedAccountBySepblac(false));
        })
        .catch(error => {
            const errorStatus = error.error.status;
            dispatch(fetchingBlockedAccountBySepblac(false));
            if (errorStatus !== 412) {
                return;
            }
            const errorKey = error.error.error;
            dispatch(
                errorActions.show('brokerAPIErrors-' + errorKey, true, history),
            );
        });
};

module.exports = {
    getBlockedAccounts,
    resetBlockedAccounts,
    getBlockedAccountBySepblac,
};


// @ vendors
const { Promise } = require('es6-promise');

// @ utilities
const { recordProcess, PROC_END, PROC_FAIL } = require('utilities/tagging');

// @ track processes
const {
    PROC_CHANGE_MORTGAGE_DATA,
    PROC_DELETE_MORTGAGE_DATA,
} = require('constants/mortgageRequestProcesses');

// @ commons
const apiURLBuilder = require('core/apiURLBuilder');
const { APIPost, APIGet } = require('utilities/APIRequestHelper');
const { get, set, reduce, every } = require('lodash');

//@actions
const { requestState } = require('actions/mortgageRequest/mortgageRequestState');

// @ constants
const {
    MORTGAGE_REQUEST_FETCH_PANEL_TITLE_SUCCESS,
    MORTGAGE_REQUEST_MORTGAGE_DATA_REQUEST,
    MORTGAGE_REQUEST_MORTGAGE_DATA_SUCCESS,
    MORTGAGE_REQUEST_MORTGAGE_DATA_FAILURE,
    MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_REQUEST,
    MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_SUCCESS,
    MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_FAILURE,
    MORTGAGE_REQUEST_MORTGAGE_FEE_RESET,
    MORTGAGE_REQUEST_MORTGAGE_FEE_REQUEST,
    MORTGAGE_REQUEST_MORTGAGE_FEE_SUCCESS,
    MORTGAGE_REQUEST_MORTGAGE_FEE_FAILURE,
    MORTGAGE_REQUEST_MORTGAGE_DELETE_REQUEST,
    MORTGAGE_REQUEST_MORTGAGE_DELETE_SUCCESS,
    MORTGAGE_REQUEST_MORTGAGE_DELETE_FAILURE,
    MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_REQUEST,
    MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_SUCCESS,
    MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_FAILURE,
    MORTGAGE_REQUEST_MORTGAGE_DATA_SET_FIELD,
    MORTGAGE_REQUEST_RESET_UPDATE_DATA_RESULT,
    MORTGAGE_REQUEST_FETCH_PANEL_TITLE_FAILURE,
    MORTGAGE_REQUEST_FETCH_TYPE_SUCCESS,
    MORTGAGE_REQUEST_FETCH_TYPE_FAILURE
} = require('constants/actionTypes');
const {
    MORTGAGE_TYPE_BY_PRODUCT_TYPE,
    YEARS_BY_PRODUCT_TYPE
} = require('constants/mortgageRequestMortgageDataTypes');
const { MORTGAGE_TYPE } = require('constants/mortgageRequestType');

// @ actions
const { mortgageRequestInsurancesLink } = require('actions/mortgageRequest/mortgageRequestManagerInsuranceRelated');

const requestMortgageDataInProgress = () => ({
    type: MORTGAGE_REQUEST_MORTGAGE_DATA_REQUEST
});

const requestMortgageDataSuccess = data => ({
    type: MORTGAGE_REQUEST_MORTGAGE_DATA_SUCCESS,
    data: data
});

const requestMortgageDataFailure = error => ({
    type: MORTGAGE_REQUEST_MORTGAGE_DATA_FAILURE,
    data: {
        error
    }
});

const requestMortgageExtraDataInProgress = () => ({
    type: MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_REQUEST
});

const requestMortgageExtraDataSuccess = data => ({
    type: MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_SUCCESS,
    data: data
});

const requestMortgageExtraDataFailure = error => ({
    type: MORTGAGE_REQUEST_MORTGAGE_EXTRA_DATA_FAILURE,
    data: {
        error
    }
});

const requestMortgageFeeReset = () => ({
    type: MORTGAGE_REQUEST_MORTGAGE_FEE_RESET
});

const requestMortgageFeeInProgress = () => ({
    type: MORTGAGE_REQUEST_MORTGAGE_FEE_REQUEST
});

const requestMortgageFeeSuccess = data => ({
    type: MORTGAGE_REQUEST_MORTGAGE_FEE_SUCCESS,
    data: data
});

const requestMortgageFeeFailure = error => ({
    type: MORTGAGE_REQUEST_MORTGAGE_FEE_FAILURE,
    data: {
        error
    }
});

const requestMortgageDeleteInProgress = () => ({
    type: MORTGAGE_REQUEST_MORTGAGE_DELETE_REQUEST
});

const requestMortgageDeleteSuccess = data => ({
    type: MORTGAGE_REQUEST_MORTGAGE_DELETE_SUCCESS,
    data: data
});

const requestMortgageDeleteFailure = error => ({
    type: MORTGAGE_REQUEST_MORTGAGE_DELETE_FAILURE,
    data: {
        error
    }
});

const mortgageRequestUpdateMortgageDataInProgress = () => ({
    type: MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_REQUEST
});

const mortgageRequestUpdateMortgageDataSuccess = data => ({
    type: MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_SUCCESS,
    data: data
});

const mortgageRequestUpdateMortgageDataFailure = error => ({
    type: MORTGAGE_REQUEST_UPDATE_MORTGAGE_DATA_FAILURE,
    data: {
        error
    }
});

const setField = (inputField, data) => ({
    type: MORTGAGE_REQUEST_MORTGAGE_DATA_SET_FIELD,
    payload: {
        inputField,
        data
    }
});

const resetUpdateResult = () => ({
    type: MORTGAGE_REQUEST_RESET_UPDATE_DATA_RESULT
});

const requestPanelTitleSuccess = data => ({
    type: MORTGAGE_REQUEST_FETCH_PANEL_TITLE_SUCCESS,
    data: data
});

const requestPanelTitleFailure = error => ({
    type: MORTGAGE_REQUEST_FETCH_PANEL_TITLE_FAILURE,
    data: {
        error
    }
});

const requestMortgageTypeSuccess = data => ({
    type: MORTGAGE_REQUEST_FETCH_TYPE_SUCCESS,
    data
});

const requestMortgageTypeFailure = error => ({
    type: MORTGAGE_REQUEST_FETCH_TYPE_FAILURE,
    data: {
        error
    }
});


const requestMortgageData = () => (dispatch, getState) => {
    const currentState = getState().mortgageRequestMortgageData;
    const isAlreadyFetching = currentState.get('isFetching');
    if (!isAlreadyFetching) {
        dispatch(requestMortgageDataInProgress());
        return APIGet(dispatch, apiURLBuilder.getURL('mortgageRequesMortgageDataRequest'))
            .then(response => {
                dispatch(requestMortgageDataSuccess(response));
                dispatch(requestPanelTitleSuccess(response));
                dispatch(requestMortgageTypeSuccess(response.data));
            })
            .catch(error => {
                if (!!error.error) {
                    dispatch(requestMortgageDataFailure(error));
                    dispatch(requestPanelTitleFailure(error));
                    dispatch(requestMortgageTypeFailure(error));
                } else {
                    throw error;
                }
            });
    } else {
        return Promise.resolve();
    }
};

const requestMortgageExtraData = () => (dispatch, getState) => {
    const currentState = getState().mortgageRequestMortgageExtraData;
    const isAlreadyFetching = currentState.get('isFetching');
    if (!isAlreadyFetching) {
        dispatch(requestMortgageExtraDataInProgress());
        return APIGet(dispatch, apiURLBuilder.getURL('mortgageRequesMortgageExtraDataRequest'))
            .then(response => dispatch(requestMortgageExtraDataSuccess(response)))
            .catch(error => {
                if (!!error.error) {
                    dispatch(requestMortgageExtraDataFailure(error));
                } else {
                    throw error;
                }
            });
    } else {
        return Promise.resolve();
    }
};

const requestMortgageFee = (
    importeVivienda,
    importeHipoteca,
    plazo,
    tipoProducto,
    idExpediente,
    incluyeSeguroHogar,
    tipoPlazoFijoMixto
) => (dispatch, getState) => {
    const currentState = getState().mortgageRequestMortgageData;
    const isAlreadyFetching = currentState.get('isFetching');

    const plazoFijo = YEARS_BY_PRODUCT_TYPE[tipoProducto === MORTGAGE_TYPE.MIXTO ? tipoPlazoFijoMixto : tipoProducto];
    const mortgageProductType = MORTGAGE_TYPE_BY_PRODUCT_TYPE[tipoProducto];
    tipoProducto = mortgageProductType;

    if (!isAlreadyFetching) {
        const requestData = {
            query: {
                importeVivienda,
                importeHipoteca,
                plazo,
                tipoProducto,
                plazoFijo,
                idExpediente,
                incluyeSeguroHogar
            }
        };
        dispatch(requestMortgageFeeInProgress());
        return APIGet({
            dispatch,
            endpoint: 'mortgageRequestFeeRequest',
            params: requestData
        })
            .then(response => dispatch(requestMortgageFeeSuccess(response)))
            .catch(error => {
                if (!!error.error) {
                    dispatch(requestMortgageFeeFailure(error));
                } else {
                    throw error;
                }
            });
    } else {
        return Promise.resolve();
    }
};

const deleteMortgage = (history) => (dispatch, getState) => {
    const currentState = getState().mortgageRequestMortgageData;
    const isAlreadyFetching = currentState.get('isFetching');

    if (!isAlreadyFetching) {
        dispatch(requestMortgageDeleteInProgress());
        return APIPost(dispatch, apiURLBuilder.getURL('mortgageRequestDeleteMortgageRequest'))
            .then(response => {
                recordProcess(PROC_DELETE_MORTGAGE_DATA, PROC_END);
                dispatch(requestMortgageDeleteSuccess(response));
                history.push('/myprofile/global');
            })
            .catch(error => {
                if (!!error.error) {
                    recordProcess(PROC_DELETE_MORTGAGE_DATA, PROC_FAIL);
                    dispatch(requestMortgageDeleteFailure(error));
                } else {
                    throw error;
                }
            });
    } else {
        return Promise.resolve();
    }
};

const validateUpdateResult = responseInit => () => {
    const data = responseInit.data;
    const effortRate = get(data, 'arraytasaesfuerzo.tasaesfuerzo', []);
    const isResultOK = every(effortRate, { 'resultadocd': '1' });
    const formatNumber = number => parseFloat(number.replace(/[\.,]/g, m => m === '.' ? '' : '.'));
    const getAge = number => parseInt(number.substr(0, 2), 10);
    const getAgeTerm = number => {
        const indStart = number.indexOf('(');
        return parseInt(number.substr(indStart + 1, 2), 10);
    };
    const reduceEffortRate = effortRateList => {
        const effortRateListReduced = reduce(effortRateList, (result, item) => {
            const valornm = get(item, 'valornm', '0');
            result[item.campocd] = {};
            result[item.campocd]['result'] = parseInt(get(item, 'resultadocd', 0), 2);
            result[item.campocd]['min'] = parseFloat(get(item, 'minnm', 0) || 0);
            result[item.campocd]['max'] = parseFloat(get(item, 'maxnm', 0) || 0);
            if (item.campocd === 'EDAD') {
                result[item.campocd]['value'] = getAge(valornm);
                result[item.campocd]['valueTotal'] = getAgeTerm(valornm);
            } else {
                result[item.campocd]['value'] = formatNumber(valornm);
            }
            return result;
        }, {});
        return effortRateListReduced;
    };
    let effortRateReduced;
    let add_holder_or_term = false;
    let add_holder_or_contact_us = false;
    let reduce_term = false;
    let response = { isFetchingUpdate: false };

    if (effortRate.length && !isResultOK) {
        effortRateReduced = reduceEffortRate(effortRate);
        const dtiRemainder = () => {
            if (effortRateReduced.EDAD.valueTotal <= effortRateReduced.EDAD.max) {
                if (effortRateReduced.PLAZO.value < effortRateReduced.PLAZO.max)
                    add_holder_or_term = true;
                if (effortRateReduced.PLAZO.value === effortRateReduced.PLAZO.max)
                    add_holder_or_contact_us = true;
            } else
                reduce_term = true;
        };
        const dtiRemainderValid = () => {
            if (
                !effortRateReduced.PLAZO.result ||
                !effortRateReduced.EDAD.result ||
                !effortRateReduced.LTV.result ||
                !effortRateReduced.IMPORTE.result ||
                !effortRateReduced.CUOTA.result ||
                !effortRateReduced.CUOTA_ESTRESADA.result
            )
                add_holder_or_contact_us = true;
        };
        const dtiRemainderInvalid = () => {
            if (
                effortRateReduced.EDAD.valueTotal === effortRateReduced.EDAD.max ||
                effortRateReduced.PLAZO.value === effortRateReduced.PLAZO.max
            )
                add_holder_or_contact_us = true;
            if (
                effortRateReduced.EDAD.valueTotal < effortRateReduced.EDAD.max &&
                effortRateReduced.PLAZO.value < effortRateReduced.PLAZO.max
            )
                add_holder_or_term = true;
        };

        if (
            (effortRateReduced.DTI.result && !effortRateReduced.REMANENTE.result) ||
            (!effortRateReduced.DTI.result && effortRateReduced.REMANENTE.result)
        )
            dtiRemainder();
        else if (effortRateReduced.DTI.result && effortRateReduced.REMANENTE.result)
            dtiRemainderValid();
        else if (!effortRateReduced.DTI.result && !effortRateReduced.REMANENTE.result)
            dtiRemainderInvalid();
    }

    if (!add_holder_or_term && !add_holder_or_contact_us && !reduce_term) {
        response.updateOk = true;
    } else {
        response.updateResult = {
            add_holder_or_term,
            add_holder_or_contact_us,
            reduce_term
        };
    }

    return response;
};

const updateMortgage = (data, shouldUpdateInsurance = false, history) => dispatch => {
    dispatch(mortgageRequestUpdateMortgageDataInProgress());
    let { tipoPlazoFijoMixto, tipoProducto } = data;
    const fixedTerm = YEARS_BY_PRODUCT_TYPE[tipoProducto === MORTGAGE_TYPE.MIXTO ? tipoPlazoFijoMixto : tipoProducto];
    const mortgageProductType = MORTGAGE_TYPE_BY_PRODUCT_TYPE[tipoProducto];
    mortgageProductType && (data.tipoProducto = mortgageProductType);
    fixedTerm && (data.plazoFijo = fixedTerm);
    const urlParams = {
        query: data
    };


    return APIPost(dispatch, apiURLBuilder.getURL('mortgageRequestUpdateMortgageData'), urlParams)
        .then(response => dispatch(validateUpdateResult(response)))
        .then(response => {
            dispatch(mortgageRequestUpdateMortgageDataSuccess(response));
            recordProcess(PROC_CHANGE_MORTGAGE_DATA, PROC_END);
            const simulationValue = get(data, 'simulacion', 1);
            if (response.updateOk && simulationValue === 1) {
                const newData = set(data, 'simulacion', 0);
                dispatch(updateMortgage(newData, shouldUpdateInsurance, history));
            } else if (response.updateOk && simulationValue === 0) {
                dispatch(requestMortgageData());
                if (shouldUpdateInsurance) {
                    dispatch(mortgageRequestInsurancesLink(data.incluyeSeguroHogar, true, history));
                } else {
                    dispatch(requestState('/myprofile/mortgage/panel', history));
                }
            }
        })
        .catch(error => {
            if (!!error.error) {
                recordProcess(PROC_CHANGE_MORTGAGE_DATA, PROC_FAIL);
                dispatch(mortgageRequestUpdateMortgageDataFailure(error));
            } else {
                throw error;
            }
        });
};

module.exports = {
    requestMortgageData,
    requestMortgageExtraData,
    requestMortgageFee,
    requestMortgageFeeReset,
    deleteMortgage,
    setField,
    updateMortgage,
    resetUpdateResult
};
